import styled from "styled-components"

import { EventType } from "src/data/events/types/eventTypes"
import { colors, semanticEmergency } from "src/ui/colors"
import SmokingDetectionIcon from "src/ui/icons/cigarette-detection.svg"
import COAlarmIcon from "src/ui/icons/eventIcons/co-alarm.svg"
import DeviceOffIcon from "src/ui/icons/eventIcons/device-off.svg"
import ErrorLogIcon from "src/ui/icons/eventIcons/error-log.svg"
import AlarmHeardIcon from "src/ui/icons/eventIcons/event-alarm-heard.svg"
import AlarmOnIcon from "src/ui/icons/eventIcons/event-alarm-on.svg"
import BatteryEmptyIcon from "src/ui/icons/eventIcons/event-battery-empty.svg"
import BatteryLowIcon from "src/ui/icons/eventIcons/event-battery-low.svg"
import CallAssistIcon from "src/ui/icons/eventIcons/event-call-assist.svg"
import DefaultIcon from "src/ui/icons/eventIcons/event-default.svg"
import DeviceIcon from "src/ui/icons/eventIcons/event-device.svg"
import DeviceOfflineIcon from "src/ui/icons/eventIcons/event-device-offline.svg"
import DeviceOnlineIcon from "src/ui/icons/eventIcons/event-device-online.svg"
import DogBarkIcon from "src/ui/icons/eventIcons/event-dog-bark.svg"
import FreezingIcon from "src/ui/icons/eventIcons/event-freezing.svg"
import GlassBreakIcon from "src/ui/icons/eventIcons/event-glass-break.svg"
import HighCrowdRiskIcon from "src/ui/icons/eventIcons/event-high-crowd-risk.svg"
import HighTemperatureIcon from "src/ui/icons/eventIcons/event-high-temperature.svg"
import HomeIcon from "src/ui/icons/eventIcons/event-home.svg"
import HumidityIcon from "src/ui/icons/eventIcons/event-humidity.svg"
import MotionDetectedIcon from "src/ui/icons/eventIcons/event-motion-detected.svg"
import MouldIcon from "src/ui/icons/eventIcons/event-mould.svg"
import NoiseIcon from "src/ui/icons/eventIcons/event-noise.svg"
import SensorSwapFailedIcon from "src/ui/icons/eventIcons/event-sensor-swap-failed.svg"
import SensorSwapSuccessIcon from "src/ui/icons/eventIcons/event-sensor-swap-success.svg"
import TamperIcon from "src/ui/icons/eventIcons/event-tamper.svg"
import TemperatureIcon from "src/ui/icons/eventIcons/event-temperature-medium.svg"
import TemperatureRapidRiseIcon from "src/ui/icons/eventIcons/event-temperature-rapid-rise.svg"
import UserInviteIcon from "src/ui/icons/eventIcons/event-user-invite.svg"
import FireAlarmIcon from "src/ui/icons/eventIcons/fire-alarm.svg"
import OkLogIcon from "src/ui/icons/eventIcons/ok-log.svg"
import ResponseServiceIcon from "src/ui/icons/response-service.svg"

export function EventIcon(eventTitle: EventType) {
  return <IconBox>{eventIconMap[eventTitle] ?? <DefaultIcon />}</IconBox>
}

export function LegacyEventIcon({ eventType }: { eventType: string }) {
  return (
    <IconBox>{eventIconMap[eventType as EventType] ?? <DefaultIcon />}</IconBox>
  )
}

const eventIconMap: { [key in EventType]: React.ReactNode } = {
  [EventType.DEVICE_ONLINE]: <DeviceOnlineIcon />,
  [EventType.DEVICE_OFFLINE]: <DeviceOfflineIcon />,
  [EventType.FIRST_HEARTBEAT]: <DeviceIcon />,
  [EventType.SECOND_HEARTBEAT_SOUND_SUMMARY]: <DeviceIcon />,
  [EventType.INTRODUCTION_DAY_0_IMMEDIATE]: <HomeIcon />,
  [EventType.TEMPERATURE_HIGH]: <HighTemperatureIcon />,
  [EventType.TEMPERATURE_LOW]: <TemperatureIcon />,
  [EventType.TEMPERATURE_DROPPED_NORMAL]: <TemperatureIcon />,
  [EventType.TEMPERATURE_RISEN_NORMAL]: <TemperatureIcon />,
  [EventType.TEMPERATURE_RAPID_RISE]: (
    <TemperatureRapidRiseIcon style={{ color: semanticEmergency }} />
  ),
  [EventType.HUMIDITY_HIGH]: <HumidityIcon />,
  [EventType.HUMIDITY_LOW]: <HumidityIcon />,
  [EventType.HUMIDITY_DROPPED_NORMAL]: <HumidityIcon />,
  [EventType.HUMIDITY_RISEN_NORMAL]: <HumidityIcon />,
  [EventType.SHORT_BUTTON_PRESS]: <DeviceIcon />,
  [EventType.ALARM_HEARD]: (
    <AlarmHeardIcon style={{ color: semanticEmergency }} />
  ),
  [EventType.AVG_SOUND_HIGH]: <NoiseIcon />,
  [EventType.PEAK_SOUND_HIGH]: <NoiseIcon />,
  [EventType.SOUND_LEVEL_DROPPED_NORMAL]: <NoiseIcon />,
  [EventType.SOUND_LEVEL_HIGH_DESPITE_WARNING]: <NoiseIcon />,
  [EventType.DISTURBANCE_FIRST_NOTICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_SECOND_NOTICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_THIRD_NOTICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_FIRST_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_SECOND_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_THIRD_NOTICE_RESPONSE_SERVICE]: <NoiseIcon />,
  [EventType.DISTURBANCE_DISPATCHED_AUTOMATICALLY]: <NoiseIcon />,
  [EventType.DISTURBANCE_DISPATCHED_MANUALLY]: <NoiseIcon />,
  [EventType.DISTURBANCE_LEVEL_4_AUTOMATICALLY]: <NoiseIcon />,
  [EventType.DISTURBANCE_LEVEL_4_MANUALLY]: <NoiseIcon />,
  [EventType.SOUND_OF_INTEREST]: <NoiseIcon />,
  [EventType.OCCUPANCY_SCANNED_DEVICE_LIMIT_EXCEEDED]: (
    <HighCrowdRiskIcon style={{ color: semanticEmergency }} />
  ),
  [EventType.TAMPER]: <TamperIcon />,
  [EventType.TAMPER_MOUNTED]: <TamperIcon />,
  [EventType.TAMPER_REMOVED]: <TamperIcon />,
  [EventType.DEVICE_POWER_OFF]: <DeviceOffIcon />,
  [EventType.DEVICE_INSTALL]: <DeviceIcon />,
  [EventType.ALARM_MUTED_BUTTON_PRESS]: <DeviceIcon />,
  [EventType.ALARM_GRACE_PERIOD_EXPIRED]: (
    <MotionDetectedIcon style={{ color: semanticEmergency }} />
  ),
  [EventType.ROOM_TOO_WARM]: <HighTemperatureIcon />,
  [EventType.BEDROOM_TOO_WARM]: <HighTemperatureIcon />,
  [EventType.ALMOST_FREEZING]: <FreezingIcon />,
  [EventType.RISK_OF_MOULD]: <MouldIcon />,
  [EventType.RISK_OF_MOULD_CLEARED]: <MouldIcon />,
  [EventType.BATTERY_LOW]: <BatteryLowIcon />,
  [EventType.BATTERY_EMPTY]: <BatteryEmptyIcon />,
  [EventType.CHARGER_CONNECTED]: <DeviceIcon />,
  [EventType.CHARGER_DISCONNECTED]: <DeviceIcon />,
  [EventType.BATTERY_CHARGING_COMPLETE]: <DeviceIcon />,
  [EventType.WIFI_RECONFIGURED]: <DeviceOnlineIcon />,
  [EventType.GLASSBREAK]: (
    <GlassBreakIcon style={{ color: semanticEmergency }} />
  ),
  [EventType.USER_INVITED_ANOTHER_USER_TO_HOME]: <UserInviteIcon />,
  [EventType.USER_RECEIVED_INVITATION_TO_OTHER_HOME]: <UserInviteIcon />,
  [EventType.INVITATION_ACCEPTED_TO_HOME_MEMBERS_VIEW]: <UserInviteIcon />,
  [EventType.NON_EXISTING_USER_INVITED_TO_HOME]: <UserInviteIcon />,
  [EventType.DOG_BARK]: <DogBarkIcon />,
  [EventType.ALARM_ON_AUTOMATICALLY]: <AlarmOnIcon />,
  [EventType.RESPONSE_SERVICE_DISPATCH_ARRIVED]: <ResponsePartnerIcon />,
  [EventType.RESPONSE_SERVICE_DISPATCH_IN_PROGRESS]: <ResponsePartnerIcon />,
  [EventType.RESPONSE_SERVICE_DISPATCH_COMPLETED]: <ResponsePartnerIcon />,
  [EventType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_USER]: (
    <ResponsePartnerIcon />
  ),
  [EventType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_PROVIDER]: (
    <ResponsePartnerIcon />
  ),
  [EventType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_IN_PROGRESS]: (
    <ResponsePartnerIcon />
  ),
  [EventType.RESPONSE_SERVICE_DISPATCH_CANCELLED_BY_MINUT_WHILE_REQUESTED]: (
    <ResponsePartnerIcon />
  ),
  [EventType.RESPONSE_SERVICE_DISPATCH_REQUESTED_AUTOMATICALLY]: (
    <ResponsePartnerIcon />
  ),
  [EventType.RESPONSE_SERVICE_DISPATCH_REQUESTED_MANUALLY]: (
    <ResponsePartnerIcon />
  ),
  [EventType.SMOKING_DETECTION_CALIBRATION_DONE]: (
    <SmokingDetectionIcon width={40} height={40} />
  ),
  [EventType.SMOKING_DETECTION_SMOKING_DETECTED]: (
    <SmokingDetectionIcon width={40} height={40} />
  ),
  [EventType.CALLCENTER_GUEST_CALL]: <CallAssistIcon width={40} />,
  [EventType.CALLCENTER_GUEST_CALL_COMPLETED]: <CallAssistIcon width={40} />,
  [EventType.CALLCENTER_GUEST_CALL_NO_ANSWER]: <CallAssistIcon width={40} />,
  [EventType.SENSOR_SWAP_SUCCEEDED]: <SensorSwapSuccessIcon />,
  [EventType.SENSOR_SWAP_FAILED]: <SensorSwapFailedIcon />,

  // Eureka
  [EventType.FIRE_SMOKE_ALARM]: (
    <FireAlarmIcon color={colors.semanticEmergency} />
  ),
  [EventType.FIRE_SMOKE_CLEARED]: <FireAlarmIcon />,
  [EventType.CO_ALARM]: <COAlarmIcon color={colors.semanticEmergency} />,
  [EventType.CO_CLEARED]: <COAlarmIcon />,
  [EventType.FIRE_CO_SELF_TEST_SUCCESS]: <OkLogIcon />,
  [EventType.FIRE_CO_SELF_TEST_FAIL]: <ErrorLogIcon />,

  // Events with undefined icons:
  [EventType.FIRST_MORNING]: undefined,
  [EventType.ALARM_DETECTION_TEST]: undefined,
  [EventType.INTRODUCTION_DAY_1]: undefined,
  [EventType.INTRODUCTION_DAY_2_DEVICE_INSTALLED]: undefined,
  [EventType.INTRODUCTION_DAY_2_NO_DEVICE]: undefined,
  [EventType.INTRODUCTION_DAY_4]: undefined,
  [EventType.INTRODUCTION_DAY_7]: undefined,
  [EventType.SMOKE_DETECTED]: undefined,
  [EventType.PIR_MOTION]: undefined,
  [EventType.ALARM_SILENCED]: undefined,
  [EventType.ALARM_OFF_AUTOMATICALLY]: undefined,
  [EventType.ALARM_NOT_AUTO_ON_ALL_DEVICES_OFFLINE]: undefined,
  [EventType.INVITATION_ACCEPTED_TO_HOME_INVITEE_VIEW]: undefined,
  [EventType.USER_ARRIVED_HOME]: undefined,
  [EventType.USER_LEFT_HOME]: undefined,
  [EventType.MEMBER_ARRIVED_HOME]: undefined,
  [EventType.MEMBER_LEFT_HOME]: undefined,
  [EventType.TURN_ON_ALARM_REMINDER]: undefined,
  [EventType.TURN_OFF_ALARM_REMINDER]: undefined,
  [EventType.LAST_MEMBER_LEFT]: undefined,
  [EventType.FIRST_MEMBER_ARRIVED]: undefined,
  [EventType.SMOKE_DETECTOR_TEST_REMINDER]: undefined,
  [EventType.WEBHOOK_PING]: undefined,
  [EventType.AIR_TOO_DRY]: undefined,
  [EventType.AIR_TOO_HUMID]: undefined,
  [EventType.DISTURBANCE_ENDED]: undefined,
  [EventType.DISTURBANCE_DISMISSED]: undefined,
  [EventType.DISTURBANCE_SNOOZED]: undefined,
  [EventType.OCCUPANCY_SCANNED_DEVICE_LIMIT_SUBCEEDED]: undefined,
}

function ResponsePartnerIcon() {
  return (
    <IconBox>
      <ResponseServiceIcon />
    </IconBox>
  )
}

const IconBox = styled.div`
  & > svg {
    width: 40px;
    height: 40px;
    fill: currentColor;
  }
`
